//改为使用LocalStorage做缓存
let CookieHelp = {
	clearFlag: '{}',
	userCookieKey: "DEMON_USER",
	//获取用户信息
	//获取用户信息
	getUserInfo(keyName) {
		if (!this.first) {
			this.getUserInfoMERO(this.getUserKey(keyName));
		}
		this.first = true;
		return this.userinfo;
	},
	/**
	 * Save User Info to Memo
	 * @param {* User keyname} keyName
	 * @param {* User object} userinfo
	 */
	getUserInfoMERO(keyName, userinfo) {
		if (!userinfo) {
			let value = this.getCookieInfo(keyName);
			if (this.clearFlag === value || !value) {
				this.userinfo = null;
			} else {
				try {
					this.userinfo = JSON.parse(value);
				} catch (e) {
					this.userinfo = JSON.parse(decodeURIComponent(value));
				}
			}
		} else {
			this.userinfo = userinfo !== this.clearFlag ? userinfo : null;
		}
	},
	/**
	 * Save User Info Cookie
	 * @param {* user info object} userinfo 
	 * @param {* day to keep cookie} save 
	 * @param {* user key name} keyName 
	 */
	saveUserInfo(userinfo, save, keyName) {
		this.saveCookieInfo(this.getUserKey(keyName), userinfo, save)
		this.getUserInfoMERO(this.getUserKey(keyName), userinfo);
	},
	clearUserInfo() {
		this.saveUserInfo(this.clearFlag);
	},
	//设置

	//获取某个cookie的值
	getCookieInfo(cookiename) {
		let o =  window.localStorage.getItem(cookiename)
		console.log(o);
		return o?JSON.parse(o).v:null;
	},
	getUserKey(keyName) {
		this.userCookieKey = keyName || this.userCookieKey;
		return this.userCookieKey;
	},
	saveCookieInfo(key, value, time) {
		let v = '';
		if (typeof value == "string") {
			v = value;
		} else {
			v = JSON.stringify(value)
		}

		let str = JSON.stringify( {v:v});
		window.localStorage.setItem(key, str);
	},

	//退出登录，清除cookie
	clearCookie() {

	}
}
export default  CookieHelp;